import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// 预览图片
import hevueImgPreview from 'hevue-img-preview'

// UI主题样式
import '@/assets/styles/theme.scss'
// vuex
import store from './store'
// 表单验证
import VeeValidate from 'vee-validate'
import vv from '@/validate/extend'
vv()
import { ValidationProvider, ValidationObserver } from 'vee-validate';
// 时间选择弃
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/zh-cn';
// 项目样式
import '@/assets/styles/main.less'
import messages from '@/validate/messages'

import './components/upload/upload.less'
import ElUpload from './components/upload/'

import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'


// el 组件
import {
  Select, Option, Input, Button, Checkbox, CheckboxGroup, Tag, DatePicker as ELDatePicker, Form, FormItem, Loading, Radio, RadioGroup, Table, TableColumn, InputNumber, Slider, Autocomplete, Cascader
} from 'element-ui';
Vue.use(Select)
Vue.use(Option)
Vue.use(Input)
Vue.use(Button)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(ELDatePicker)
Vue.use(Tag)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Loading)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(InputNumber)
Vue.use(Slider)
Vue.use(Autocomplete)
Vue.use(Cascader)
import './element-theme.scss'

Vue.mixin({
  data() {
    return {
      inquiryStatus: {
        100: '未接入',
        200: '洽谈中',
        500: '已关闭',
      },
      inquiryStatusOptions: [
        {
          label: '未接入',
          value: 100
        },
        {
          label: '洽谈中',
          value: 200
        },
        {
          label: '已关闭',
          value: 500
        }
      ],
      userStatus: {
        100: '待审核',
        200: '已审核',
        400: '驳回',
      },
      gmvOptions: {
        A: '100W',
        B: '100-300W',
        C: '300-700W',
        D: '700-1500W',
        E: '1500W+'
      },
      businessYearOptions: {
        A: '2年',
        B: '2-5年',
        C: '5-10年',
        D: '10年+'
      },
      // 业绩
      salesOptions: [
        {
          id: 'A',
          label: '100W'
        },
        {
          id: 'B',
          label: '100-300W'
        },
        {
          id: 'C',
          label: '300-700W'
        },
        {
          id: 'D',
          label: '700-1500W'
        },
        {
          id: 'E',
          label: '1500W+'
        }
      ],
      // 年数
      yearsOptions: [
        {
          id: 'A',
          label: '2年'
        },
        {
          id: 'B',
          label: '2-5年'
        },
        {
          id: 'C',
          label: '5-10年'
        },
        {
          id: 'D',
          label: '10年+'
        }
      ],
      //月销量
      mSale: {
        1: '30以下',
        2: '30-100',
        3: '100-300',
        4: '300以上'
      },
      // 产品定位
      pp: {
        1: 'A+独家',
        2: 'A 高门槛',
        3: 'B 普货'
      },
      // 大于今天不可选
      disabledBeforeToday(date) {
        const today = new Date();
        return date > today;
      },
      pickerOptionsSetting: {
        disabledDate: (date) => {
          const today = new Date();
          return date > today;
        },
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      storePlaceDic: {
        1: '美西',
        2: '美东',
        3: '美南'
      },
      isBig: (item) => {
        const sizeArr = [item.width, item.height, item.length].sort((a, b) => b - a)
        return sizeArr[0] + 2 * (sizeArr[1] + sizeArr[2]) >= 300
      },
      thirdParty: {
        5: 1,
        9: 2
      },
      thirdPartyString: {
        1: '瑞元',
        2: '发现',
        5: '瑞元',
        9: '发现'
      },
      requireStatus: {
        0: '未报价',
        1: '已报价'
      }
    }
  },
  computed: {
    // 获取用户
    user() {
      return this.$store.state.user
    },
    // 区域
    areas() {
      return this.$store.state.areas
    },
    // 是否管理员
    isAdmin() {
      return this.$store.state.isAdmin
    }
  },
  methods: {
    getQuery() {
      const { keyword, year, brand, model, r, cid, labelIds, stocks } = this.$route.query
      return Object.assign(
        {},
        {
          page: 1
        },
        keyword && keyword.trim() ? { keyword: keyword.trim() } : {},
        year ? { year } : {},
        brand ? { brand } : {},
        model ? { model } : {},
        cid ? { cid } : {},
        r ? { r } : {},
        labelIds ? { labelIds } : {},
        stocks ? { stocks } : {}
      )
    },
    // 去跨综合服务
    goTocbec() {
      this.$bvToast.toast('服务正在升级维护中，请稍后访问', {
        title: '提醒',
        variant: 'warning',
        toaster: 'b-toaster-top-center',
        autoHideDelay: 1500
      })
      // window.open('https://cbec.seedslinks.com/')
    },
    // 必填验证
    vState(ref) {
      if (this.veeFields[ref] && this.veeFields[ref].dirty) {
        return false
      }
      return null
    },
    // 获取询单状态
    getInquiryStatus(state) {
      if (state) return this.inquiryStatus[state]
      return null
    },
    // 获取用户状态
    getUserStatus(state) {
      if (state) return this.userStatus[state]
      return null
    },
    // 预览图片
    previewImg(url) {
      this.$hevueImgPreview({
        url,
        clickMaskCLose: true
      })
    },
    // 获取省
    getProvince() {
      return this.areas
    },
    // 控制body滚动
    bodyCanScroll(can) {
      document.body.style.overflow = can ? 'auto' : 'hidden'
    },
    // 显示同意协议
    showAgreement() {
      const dialog = this.$refs.agreement
      if (dialog) {
        dialog.showAgreementDialog = true
      }
    },
    showAgreement2() {
      const dialog = this.$refs.agreement2
      if (dialog) {
        dialog.showAgreementDialog = true
      }
    }
  }
})

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(hevueImgPreview)
Vue.use(VeeValidate, {
  locale: 'zh_CN',
  dictionary: {
    zh_CN: { messages },
  },
  inject: true,
  fieldsBagName: 'veeFields',
  errorBagName: 'veeErrors'
})

Vue.component('date-picker', DatePicker)
Vue.component('v-p', ValidationProvider)
Vue.component('v-o', ValidationObserver)
Vue.component('VueSlider', VueSlider)
Vue.use(ElUpload)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
